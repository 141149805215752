.parent {
  height: 100%;
}

.console {
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 1rem;
  margin: 1rem;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.console > h1 {
  color: var(--text-primary);
  text-align: left;
  margin: 0;
  padding: 0 1rem 0 1rem;
}

.album-editor {
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-template-rows: auto;
  gap: 10px;
  padding: 1rem;
}

.album-editor::-webkit-scrollbar {
  width: 0.25rem;
}

.album-editor::-webkit-scrollbar-track {
  background: #1e1e24;
}

.album-editor::-webkit-scrollbar-thumb {
  background: #6649b8;
}

.album-editor > .album, .manage-btn {
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  transition: all var(--transition-speed);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
}

.album-editor > .album:hover {
  transform: translate(2px, -2px);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.manage-btn {
  margin-top: 1rem;
}

.manage-btn > h1 {
  color: var(--text-primary);
}

.manage-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.album-editor > .album > h1 {
  color: var(--text-secondary);
  padding: 0px;
  margin: 0px;
}

@media only screen and (max-width: 600px) {
  .parent {
    height: calc(100vh - 56px);
    margin-bottom: 56px;
  }

  .console > h1 {
    padding: 0 0 1rem 0;
  }

  .album-editor {
    max-height: 100%;
    padding: 0;
    grid-template-columns: 1fr;
  }
}
