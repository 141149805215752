.AlbumItem {
    margin: 1rem;
    margin-bottom: 2rem;
}

.AlbumCard {
    border-radius: 16px;
    /* background-color: #13231e40;
    background-image: linear-gradient(315deg, #13231e40 0%, #ffffff40 74%); */
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 3rem;
}

.AlbumCard:hover .AlbumTitle {
    color: var(--highlight);
    transition: var(--transition-speed);
}

.PhotoStack {
    width: 60vh;
    height: 50vh;
    /* flex-grow: 1; */
    /* position: relative; */
}

.PhotoStackHolder {
    position: relative;
}

.AlbumTitle {
    color: var(--text-secondary);
    transition: var(--transition-speed);
    /* height: 6vh; */
    align-self: center;
    font-size: 5vh;
    text-align: right;
    margin: 3vw;
}

.AlbumTitle:hover {
    color: var(--highlight);
    transition: var(--transition-speed);
}

.loading {
    color: var(--bg-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: var(--bg-primary);
    border-width: 1px;
    border-radius: 8px;
}

.photoStackIMG {
    position: absolute;
    border-radius: 8px;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    .AlbumTitle{
        margin: 0;
        margin-top: 2rem;
    }
    .AlbumCard {
        padding: 2rem;
    }
}