.About {
  height: 100%;
}

.About-Contents {
  margin-top: 20px;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
}

.svgdiv {
  background-color: #000000cc;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.card-animate-float {
    animation: float 6s ease-in-out infinite;
}

.card-1 {
  transform: rotate(-5deg) translate(-50px, 45px);
  width: 400px;
}

.card-2 {
  transform: rotate(5deg) translate(0, 45px);
  width: 400px;
}

.card-3 {
  transform: rotate(5deg) translate(0, 45px);
  width: 400px;
}

.card-4 {
  transform: rotate(-5deg) translate(-50px, 45px);
  width: 400px;
}

.image-style {
  height: 150px;
  width: 100%;
  object-fit: cover;
}

.carousel-item {
  height: 150px;
}

.interactive:hover {
  translate: 50px;
}

.about-card-slot {
  margin: 100px;
}

.card {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.card-only {
  border-radius: 23px;
}

/* .card:before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: -1;
  top: -25px;
  left: -25px;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 3000px rgba(255, 255, 255, 0.5);
  filter: blur(10px);
  margin: -20px;
} */
.card-tall {
  grid-row: span 2 / auto;
}

.card .title {
  align-self: flex-start;
}

.card .header,
.footer {
  width: 100%;
  background-color: rgba(38, 38, 38, 0.8);
  text-align: start;
}

.card .header {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.card .body-only {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.card .footer {
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  overflow: auto;
}
.footer::-webkit-scrollbar {
  width: 0.25rem;
}

.footer::-webkit-scrollbar-track {
  background: #1e1e24;
}

.footer::-webkit-scrollbar-thumb {
  background: #6649b8;
}

.card .body {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgb(83, 140, 74);
  background: linear-gradient(
    120deg,
    /* rgba(83, 140, 74, 0.5) 0%, */ rgba(255, 255, 255, 0.5) 0%,
    rgba(191, 176, 170, 0.3) 81%
  );
}

/* TODO This gradient changes */

.card .inline-body {
  display: flex;
  padding: 8px;

  background: rgb(83, 140, 74);
  background: linear-gradient(
    161deg,
    rgba(52, 30, 23, 0.7) 0%,
    rgba(25, 41, 29, 0.9) 100%
  );
}

.card .bold-body {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  /* background: rgb(215, 0, 0); */
  background: linear-gradient(
    161deg,
    rgba(52, 30, 23, 0.7) 0%,
    rgba(25, 41, 29, 0.9) 100%
  );
}

.card .bold-body h1 {
  margin: 0.5rem;
  font-size: 3rem;
  /* color: #242424; */
  color: var(--text-primary);
}

.card .body p {
  margin: 0.75rem;
  text-align: left;
  word-wrap: break-word;
}

.card p {
  color: var(--text-secondary);
  font-size: 1.5rem;
  margin: 1rem;
}

.card h1,
h2,
h3 {
  color: var(--text-primary);
  margin-left: 1rem;
}

@media only screen and (max-width: 600px) {
  .About {
    margin-bottom: 56px;
  }
  .svgdiv {
    margin-left: 0;
  }
  .card-1, .card-2, .card-3, .card-4 {
    transform: none;
    width: auto;
  }
  .About-Contents {
    padding: 16px;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-template-rows: auto;
    gap: 1rem;
  }
}


@keyframes float {
  0% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(0px);
  }
  50% {
    /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
    transform: translatey(-10px);
  }
  100% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(0px);
  }
}