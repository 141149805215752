code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "VT323";
  src: url("./VT323/VT323-Regular.ttf");
}

:root {
  font-size: 16px;
  font-family: "VT323", monospace;
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --highlight: #62e881;
  --bg-primary: #13231e;
  --bg-primary-transparent: #13231e26;
  --bg-secondary: #141816;
  --bg-secondary-transparent: #14181626;
  --bg-normal: #1b2824;
  --transition-speed: 600ms;
}

body {
  margin: 0;
  font-family: "VT323", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-primary);
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url("./components/svgs/trees.jpg");
  background-size: cover;
  background-position: center;
  z-index: -1;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #6649b8;
}

.center {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
}

@media only screen and (min-width: 600px) {
  .App main {
    margin-left: 5rem;
    height: 100vh;
  }
}

.apple {
  height: calc(100vh - 156px);
}

.notApple {
  height: calc(100vh - 56px);
}
