.photoGallery {
    /* background: var(--bg-normal); */
    padding: 8px;
}

.lazy-image-style {
    border-radius: 8px;
    border: 1.5px solid var(--bg-primary-transparent)
}

.lazy-load-image-background {
    opacity: 0.8;
    padding: 2px;
    transition: var(--transition-speed);
}

.lazy-load-image-background:hover {
    opacity: 1;
    transform: scale(0.95);
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .photoGallery {
        margin-bottom: 56px;
    }
}
