.parent {
  display: flex;
  height: 94vh;
  flex-direction: column;
}

.disableInteraction {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.title-holder {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.settings-icon {
  width: 56px;
  fill: var(--highlight);
}

.title {
  padding-top: 5vh;
  padding-left: 5vw;
  padding-bottom: 5vh;
  font-size: 10vw;
  text-align: left;
  color: var(--text-secondary);
}

.Albums {
  width: 80vw;
  align-self: center;
}

@media only screen and (max-width: 600px) {
  .parent {
    overflow: auto;
  }

  .Albums {
    width: 100%;
  }

  .title {
    padding-left: 10vw;
    padding-bottom: 0;
  }
}
