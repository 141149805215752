.navbar {
  z-index: 100;
  font-family: "Open Sans", sans-serif;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  transition: var(--transition-speed) ease;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.nav-item {
  width: 100%;
}

.nav-item:last-child {
  margin-top: auto;
}

.navbar:hover {
  width: 16rem;
  background-color: var(--bg-primary);
}

.nav-link {
  display: flex;
  align-items: center;
  height: 5rem;
  color: var(--text-primary);
  text-decoration: none;
  filter: grayscale(100%) opacity(0.7);
  transition: var(--transition-speed);
}

.nav-link:hover {
  filter: grayscale(0%) opacity(1);
  background: var(--bg-secondary);
  color: var(--text-secondary);
}

.link-text {
  display: none;
  margin-left: 1rem;
  display: inline;
  position: absolute;
  left: 0px;
  opacity: 0;
  transition: all var(--transition-speed) ease;
  -moz-transition: all var(--transition-speed) ease;
  -webkit-transition: all var(--transition-speed) ease;
}

.nav-link svg {
  width: 2rem;
  min-width: 2rem;
  margin: 0 1.5rem;
}

.fa-primary {
  color: #62e881;
}

.fa-secondary {
  color: #df49a6;
}

.fa-primary,
.fa-secondary {
  transition: var(--transition-speed);
}

.logo {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--text-secondary);
  background: var(--bg-secondary-transparent);
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.3ch;
  width: 100%;
}

.logo svg {
  transform: rotate(0deg);
  transition: var(--transition-speed);
}

.logo-text {
  display: inline;
  position: absolute;
  left: -999px;
  transition: var(--transition-speed);
}

.navbar:hover .logo {
  transition: all var(--transition-speed) ease;
  background: var(--bg-secondary);
}

.navbar:hover .logo svg {
  transform: rotate(-180deg);
}

/* Small Screens */
@media only screen and (max-width: 600px) {
  .navbar {
    bottom: 0;
    width: 100vw;
    background-color: var(--bg-primary);
  }

  .navbar:hover {
    width: 100vw;
  }

  .logo {
    display: none;
  }

  .navbar-nav {
    flex-direction: row;
  }

  .nav-link {
    height: 56px;
    justify-content: center;
  }

  .nav-link svg {
    height: 1rem;
  }

  .nav-item:last-child {
    margin-top: 0;
  }

  .link-text {
    display: none;
    position: relative;
  }
}

/* Large screens */
@media only screen and (min-width: 600px) {
  .navbar {
    top: 0;
    width: 5rem;
    height: 100vh;
  }

  .navbar:hover .link-text {
    display: inline;
    margin-left: 1rem;
    left: 100px;
    opacity: 1;
  }

  .navbar:hover .logo svg {
    margin-left: 11rem;
  }

  .navbar:hover .logo-text {
    left: 0px;
  }
}
